<template>
  <div class="row">
    <div class="col-12">
      <b-card>
        <GoodDataTable button-label="Add Warehouse" ref="warehouses" class="w-100" mode="local" :no-serial="true" :columns="columns" :load-local-data="true" @add-new-info="addWareHouse" />
      </b-card>
    </div>
    <div class="col-12">
      <WarehouseForm :whid="whid" @dataSaved="dataSaved" :modalState="formModal" @modalHide="modalHide" />
    </div>
  </div>
</template>

<script>
import { isEmpty } from '@/utilities'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import WarehouseForm from '@/views/store/WarehouseForm.vue'
import { showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'ShopWareHouses',
  components: {
    GoodDataTable,
    WarehouseForm,
  },
  data: () => ({
    loading: true,
    formModal: false,
    whid: null,
  }),
  computed: {
    ...mapGetters('warehouse', ['sotreWareHouses']),
    storeInfo: {
      get() {
        return window.SuperApp.getters.userInfo()
      },
    },
    columns: {
      get() {
        return [
          {
            field: 'name',
            label: 'Warehouse Name',
            useResolver: true,
            useHtml: true,
            renderer: props => `${props.name}`,
          },
          {
            field: 'poc_name',
            label: 'POC Name',
            useResolver: true,
            useHtml: true,
            renderer: props => props.poc_name || '-N/A-',
          },
          {
            field: 'poc_email',
            label: 'POC Email',
            useResolver: true,
            useHtml: true,
            renderer: props => props.poc_email || '-N/A-',
          },
          {
            field: 'poc_phone',
            label: 'POC Phone',
            useResolver: true,
            useHtml: true,
            renderer: props => props.poc_phone || '-N/A-',
          },
          {
            field: 'city',
            label: 'City',
            useResolver: true,
            useHtml: true,
            renderer: props => props.city_name || '-N/A-',
          },
          {
            label: 'Action',
            field: 'action',
            sortable: false,
            type: 'dropdown',
            buttons: [
              {
                icon: 'Edit Details',
                title: 'Edit Details',
                image: this.$helpers.getIcons('edit'),
                action: props => {
                  this.updateWareHouse(props)
                },
              },
            ],
          },
        ]
      },
    },
  },
  methods: {
    isEmpty,
    ...mapActions('warehouse', ['getWareHouses']),
    addWareHouse() {
      this.formModal = true
    },
    updateWareHouse(wh) {
      this.whid = wh.id
      this.formModal = true
    },
    dataSaved(msg) {
      showSuccessNotification(this, msg || 'Data save successfully')
      this.formModal = false
      this.whid = null
    },
    modalHide() {
      this.formModal = false
      this.whid = null
    },
  },
  watch: {
    formModal: {
      handler(nv) {
        if (nv === false) {
          this.whid = null
        }
      },
      deep: true,
      immediate: true,
    },
    sotreWareHouses: {
      handler(nv) {
        if (this.$refs.warehouses) {
          this.$refs.warehouses.setLocalData(nv)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.getWareHouses()
  },
}
</script>