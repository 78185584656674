<template>
    <b-modal hide-footer no-close-on-backdrop no-close-on-esc size="lg" v-model="modalState" centered :title="!isEmpty(whid) ? 'Edit Warehouse' : 'Add Warehouse'" @hide="modalHide">
        <div class="row">
            <div class="col-12">
                <b-form @submit.prevent="saveData">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="name-group" 
                                label="Warehouse Name"
                            >
                                <b-form-input 
                                    id="name" 
                                    v-model="form.name" 
                                    placeholder="Warehouse Name" 
                                    required
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="poc_name-group" 
                                label="Point of Contact Name"
                            >
                                <b-form-input 
                                    id="poc_name" 
                                    v-model="form.poc_name" 
                                    placeholder="Point of Contact Name" 
                                    required
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="poc_email-group" 
                                label="Point of Contact Email"
                            >
                                <b-form-input 
                                    id="poc_email" 
                                    type="email"
                                    v-model="form.poc_email" 
                                    placeholder="Point of Contact Email" 
                                    required
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="poc_phone-group" 
                                label="Point of Contact Phone"
                            >
                                <b-form-input 
                                    id="poc_phone" 
                                    type="number"
                                    v-model="form.poc_phone" 
                                    placeholder="Point of Contact Phone" 
                                    required
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="zip_code-group" 
                                label="Zip/Postal Code"
                            >
                                <b-form-input 
                                    id="zip_code" 
                                    type="number"
                                    v-model="form.zip_code" 
                                    placeholder="Zip/Postal Code" 
                                    required
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="country_id-group" 
                                label="Country"
                            >
                                <v-select id="country_id" v-model="form.country_id" :reduce="i => i.id" :options="countryOpts" :loading="loadingCountries" @input="onCountryChange" />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="state_id-group" 
                                label="State"
                            >
                                <v-select id="state_id" v-model="form.state_id" :reduce="i => i.id" :options="stateOpts" :loading="loadingStates" @input="onStateChange" />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group 
                                id="city_id-group" 
                                label="City"
                            >
                                <v-select id="city_id" v-model="form.city_id" :reduce="i => i.id" :options="cityOpts" :loading="loadingCities" />
                            </b-form-group>
                        </div>
                        <div class="col-12">
                            <b-button variant="success" type="submit">Submit</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { isEmpty } from '@/utilities'
import { mapActions, mapGetters } from 'vuex'
import { showErrorNotification } from '@/@core/comp-functions/ui/app';
import { useUserUi } from '../users/useUser';
import vSelect from 'vue-select'

export default {
    name: "WarehouseForm",
    components: {
        vSelect
    },
    props: {
        whid: {
            type: Number | null,
            default: () => null,
            required: false
        },
        modalState: {
            type: Boolean,
            default: () => false,
            required: false
        }
    },
    data: () => ({
        loading: false,
        form: {
            name: null,
            poc_name: null,
            poc_email: null,
            poc_phone: null,
            zip_code: null,
            country_id: null,
            state_id: null,
            city_id: null,
        },
        countries: [],
        states: [],
        cities: [],
        loadingCountries: false,
        loadingStates: false,
        loadingCities: false,
    }),
    computed: {
        ...mapGetters('warehouse', [
            'findWarehouse'
        ]),
        countryOpts: {
            get() {
                return this.countries.map(i => ({
                    label: i.country_name,
                    id: i.id
                }))
            }
        },
        stateOpts: {
            get() {
                return this.states.map(i => ({
                    label: i.state_name,
                    id: i.id
                }))
            }
        },
        cityOpts: {
            get() {
                return this.cities.map(i => ({
                    label: i.city_name,
                    id: i.id
                }))
            }
        },
    },
    methods: {
        ...mapActions('warehouse', [
            'createWareHouse',
            'updateWareHouse',
        ]),
        isEmpty,
        async saveData() {
            let saveFn = null;
            if (!isEmpty(this.whid)) {
                saveFn = this.updateWareHouse;
                this.form.id = this.whid;
            } else {
                saveFn = this.createWareHouse;
            }
            this.loading = true;
            const res = await saveFn(this.form)
            this.loading = false;
            this.$emit('dataSaved', res?.data?.message)
        },
        async getCounties(){
            this.countries = [];
            this.loadingCountries = true;
            this.loadingStates = true;
            this.loadingCities = true;
            const { countryList } = useUserUi()
            await countryList()
                .then(({ data }) => {
                    if (data.statusCode === '200') {
                        this.countries = data.responseData;
                    }
                })
                .catch(error => {
                    showErrorNotification(this, error)
                })
            this.loadingCountries = false;
            this.loadingStates = false;
            this.loadingCities = false;
        },
        async onCountryChange() {
            if (isEmpty(this.form.country_id)) return
            this.states = []
            this.cities = []
            this.form.state_id = null;
            this.form.city_id = null;
            this.loadingStates = true;
            this.loadingCities = true;
            const { getStateByCountry } = useUserUi()
              await getStateByCountry(this.form.country_id)
                .then(({ data }) => {
                    if (data.statusCode === '200') {
                        this.states = data.responseData
                    } else {
                        showErrorNotification(this, data.message)
                    }
                })
                .catch(error => {
                    showErrorNotification(this, error)
                })
            this.loadingStates = false;
            this.loadingCities = false;
        },
        async onStateChange() {
            if (isEmpty(this.form.country_id)) return
            if (isEmpty(this.form.state_id)) return
            this.form.city_id = null;
            this.cities = []
            this.loadingCities = true;
            const { getCityByStateCountry } = useUserUi()
            await getCityByStateCountry(`country_id=${this.form.country_id}&state_id=${this.form.state_id}`)
                .then(({ data }) => {
                    if (data.statusCode === '200') {
                        this.cities = data.responseData
                    } else {
                        showErrorNotification(this, data.message)
                    }
                })
                .catch(error => {
                    showErrorNotification(this, error)
                })

            this.loadingCities = false;
        },
        async updateFormData(){
            if(isEmpty(this.whid)) return false;
            const editwh = this.findWarehouse(this.whid)
            if(isEmpty(editwh)) return false;
            this.form = {
                name: editwh.name,
                poc_name: editwh.poc_name,
                poc_email: editwh.poc_email,
                poc_phone: editwh.poc_phone,
                zip_code: editwh.zip_code,
                country_id: editwh.country_id,
                state_id: editwh.state_id,
                city_id: editwh.city_id,
            }
            await this.onCountryChange();
            this.form.state_id = editwh.state_id
            await this.onStateChange();
            this.form.city_id = editwh.city_id
        },
        modalHide(){
            this.countries = [];
            this.states = [];
            this.cities = [];
            this.form = {
                name: null,
                poc_name: null,
                poc_email: null,
                poc_phone: null,
                zip_code: null,
                country_id: null,
                state_id: null,
                city_id: null,
            };
            this.$emit('modalHide')
        }
    },
    watch: {
        modalState: {
            handler(nv) {
                if (nv === true) {
                    this.getCounties();
                    this.updateFormData()
                }
            },
        },
    },
    mounted() {
        if(isEmpty(this.countries)){
            this.getCounties();
        }
    }
}
import 'vue-select/dist/vue-select.css';
</script>
